










import { defineComponent, PropType } from "@vue/composition-api";
import RasmusResetPasswordFromHashForm from "./RasmusResetPasswordFromHashForm.vue";
import { RasmusResetPasswordFromHashSettings } from ".";

export default defineComponent({
  name: "RasmusResetPasswordFromHash",
  components: {
    RasmusResetPasswordFromHashForm,
  },
  props: {
    settings: {
      type: Object as PropType<RasmusResetPasswordFromHashSettings>,
      default: undefined,
    },
  },
  setup(props) {
    const settings: RasmusResetPasswordFromHashSettings = props.settings;

    const hash = settings.hash;
    const uid = settings.uid;
    const loginLink = settings.loginLink;

    return {
      uid,
      hash,
      loginLink,
    };
  },
});

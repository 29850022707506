






















































import { defineComponent, reactive, ref, computed } from "@vue/composition-api";
import { ValidationObserver } from "vee-validate";
import LoadingButton from "@/shared/components/LoadingButton";
import { RasmusResetPasswordFromHashApiData } from ".";
import { RasmusUserPassword } from "@/features/rasmus-kids-club/shared";

import { ApiErrorType } from "@/features/rasmus-kids-club/shared";

import {
  rasmusRequest,
  redirectToPath,
  getTranslatedErrorForMessage,
} from "@/features/rasmus-kids-club/shared/helpers";

export default defineComponent({
  name: "RasmusResetPasswordFromHashForm",
  components: {
    LoadingButton,
    ValidationObserver,
    RasmusUserPassword,
  },
  props: {
    uid: {
      type: String || Number,
      default: undefined,
    },
    hash: {
      type: String,
      default: undefined,
    },
    loginLink: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    let formData = reactive({
      password: "",
      passwordConfirm: "",
    });

    let errors: ApiErrorType = reactive({
      messages: undefined,
    });

    let successMessage: { messages: undefined | string } = reactive({
      messages: undefined,
    });

    let isSubmitting = ref(false);
    const passwordComplexityTooLow = ref(false);

    const setPasswordComplexityTooLow = (bool: boolean) => {
      passwordComplexityTooLow.value = bool;
    };

    const customPasswordErrors = computed(() => {
      if (passwordComplexityTooLow.value) {
        return [
          "Bitte nutzen Sie ein sicheres Passwort. Verwenden Sie für ein sicheres Passwort Zahlen, Buchstaben (klein/groß) und Sonderzeichen.",
        ];
      } else {
        return undefined;
      }
    });

    const generateRequestData = (): RasmusResetPasswordFromHashApiData => ({
      action: "RESET_PASSWORD",
      data: {
        uid: parseInt(props.uid),
        hash: props.hash,
        password: formData.password,
        passwordRepeat: formData.passwordConfirm,
      },
    });

    const resetMessages = () => {
      errors.messages = undefined;
      successMessage.messages = undefined;
    };

    const handleSubmit = async () => {
      resetMessages();
      isSubmitting.value = true;
      const apiRequestData = generateRequestData();
      const response = await rasmusRequest<RasmusResetPasswordFromHashApiData>(
        apiRequestData
      );
      isSubmitting.value = false;

      if (response.success) {
        // successMessage.messages =
        //   "Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich nun mit Ihrem neuen Passwort anmelden.";

        redirectToPath(response.data?.redirectUrl);
      } else {
        const translatedMessage = getTranslatedErrorForMessage(
          response.message
        );

        errors.messages = translatedMessage;
      }
    };

    const customPasswordConfirmErrors = computed(() => {
      if (
        formData.password?.length &&
        formData.passwordConfirm?.length &&
        formData.password !== formData.passwordConfirm
      ) {
        return ["Die Passwörter stimmen nicht überein"];
      } else {
        return undefined;
      }
    });

    return {
      formData,
      handleSubmit,
      setPasswordComplexityTooLow,
      customPasswordErrors,
      isSubmitting,
      successMessage,
      errors,
      customPasswordConfirmErrors,
    };
  },
});
